function Social() {
    return (
        <div className="social">
            <div className="story ">
                <h1 className="pink">Social Media</h1>
                <div className="mission-text">
                    You can find us on social media. We would love to hear from you... Connect with us to stay updated.
                </div>
            </div>
            <div className="sclContainer">
                <div className="container">
                    <div className="box"><a href="https://www.facebook.com/profile.php?id=61560207281846">
                        <img alt="facebook" src="img/social/facebook.png" width="100px"/><br/>
                        <span>Facebook</span></a>
                    </div>
                    <div className="box"><a href="https://x.com/SwiftCrushApp"><img alt="x"
                                                                                            src="img/social/x.png"
                                                                                            width="100px"/><br/>
                        <span>X</span></a></div>
                    <div className="box"><a href="https://www.youtube.com/@SwiftCrush"><img alt="youtube" src="img/social/youtube.png" width="100px"/><br/>
                        <span>YouTube</span></a></div>
                </div>
                <div className="container">

                    <div className="box">
                        <a href="https://www.instagram.com/swiftcrushdating">
                            <img alt="instagram"
                                                                                              src="img/social/instagram.png"
                                                                                              width="100px"/><br/>
                            <span>Instagram</span></a></div>
                    <div className="box"><a href="https://www.tiktok.com/@swiftcrushapp"><img alt="tiktok" src="img/social/tik-tok.png" width="100px"/><br/>
                        <span>TikTok</span></a></div>
                </div>
            </div>
        </div>
    );
}

export default Social;