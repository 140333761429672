function NotFound() {
    return (
        <div style={{"background": "#F9F5F6", "height": "100%", "width": "100%", "flex": 1}}>
            <div className="chevronBack"><a href="/" style={{"textDecoration": "none"}}>‹</a></div>
            <img alt="logo" src="img/heart.png" className="heart"/>
            <div className="clipSlogan"><h1 className="slogan" style={{"color": "#99627A"}}>SwiftCrush</h1>
                <span className="connect slogan">Connect in an instant!</span>
            </div>
            <h1 className="roboto-thin" style={{"color": "black", "fontSize": "3.5rem"}}>404</h1>
            <h1 className="roboto-thin" style={{"color": "black", "fontSize": "3rem"}}> Page Not Found</h1>
        </div>
    )
}

export default NotFound;