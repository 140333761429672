function MainPage() {
    return (
        <div className="mainPage" id="home">
            <img alt="logo" src="img/heart.png" className="heart"/>
            <div className="clipSlogan">
                    <h1 className="slogan">SwiftCrush</h1>
                    <span className="connect slogan">Connect in an instant!</span>
            </div>
            <h2>
                <a href="https://play.google.com/store/apps/details?id=com.swiftcrush"><img alt="play" src="img/google-play.png"/></a>
                <a href="https://apps.apple.com/app/swiftcrush/id6502757367"><img alt="appstore" src="img/app-store.png"/></a>
            </h2>
            <video id="background-video" autoPlay muted loop poster="img/poster.jpeg">
                <source src="https://assets.swiftcrush.com/videos/1.mp4" type="video/mp4"/>
            </video>
        </div>
    )
}

export default MainPage;