import Footer from "./Footer";

function Privacy() {
    return (
        <div className="privacy privacySite">
            <div className="chevronBack"><a href="/" style={{"textDecoration": "none"}}>‹</a></div>
            <div className="logo-right">
                <a href="/">
                    <img alt="logo" src="img/heart.png" width="40"></img>
                    <span style={{color: "#99627A"}}>SwiftCrush</span>
                </a>
            </div>
            <div className="story ">
                <h1 className="pink">Privacy Policy</h1>
                <div className="container">
                    <div className="half">

                        <div className="mission-text" >
                            You are in safe hands with us.
                            <br/>We designed our product with the utmost respect for your
                            security and
                            privacy, striving to deliver the best possible experience while ensuring compliance with the
                            highest
                            standards to protect your data.


                        </div>
                    </div>
                    <div className="half privacyUl">
                        <span>This Privacy Policy applies from the 25th of May, 2024.</span>
                        <ul>
                            <li><a href="#section1">Who Are We</a></li>
                            <li><a href="#section2">What Information do we collect</a></li>
                            <li><a href="#section3">Why we need your information and how we use it</a></li>
                            <li><a href="#section4">How and why we share you information</a></li>
                            <li><a href="#section5">Your rights</a></li>
                            <li><a href="#section6">Restrictions</a></li>
                            <li><a href="#section7">How long your information are kept</a></li>
                            <li><a href="#section8">Further Changes</a></li>
                            <li><a href="#section9">Waiver</a></li>
                            <li><a href="#section10">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="section1">
                <div className="mission-text-terms">Who Are We</div>
                <div className="terms-text">
                    The company responsible for your information under this <b>Privacy Policy</b> is
                    <b className="pink">&nbsp;BYTECHERRY LTD</b>, company registered in England and Wales.<br/>
                    <b>Company number: 13367745.</b>
                    <b>
                        <br/><br/>
                        3rd Floor<br/>
                        86-90 Paul Street<br/>
                        EC2A 4NE<br/>
                        London, United Kingdom<br/>
                    </b>
                </div>
            </div>
            <div id="section2">
                <div className="mission-text-terms">What Information do we collect</div>
                <div className="terms-text">
                    To provide you with the service, we need to collect some basic information from you, such as:
                    <ul>
                        <li>Your name</li>
                        <li>Date of birth</li>
                        <li>Your Location</li>
                        <li>Your Gender</li>
                        <li>Your dating preferences</li>
                        <li>At least one of your photos</li>
                        <li>Your e-mail</li>
                    </ul>

                    These pieces of information are mandatory and necessary to use our app and for the service to work,
                    you provide them when registering your account.
                    <br/>
                    In addition, we may also collect information such as your dating preferences, activity, or bio,
                    which are not mandatory and can be added while completing your profile.
                    <br/> <br/>
                    Some information about your use of our services can also be collected, such as access logs,
                    information from third parties, analytics, and details from your social media account if you use it
                    to log in.
                    <br/> <br/>
                    Further information can be obtained by allowing us to access your camera, photo album, or by any
                    other means after requesting your permission to access such content beforehand.
                    <br/>
                    Occasionally, we can collect some extra information by inviting you to participate in surveys,
                    testimonials, or any other studies to gather your insights about our product. <br/>This occurs when
                    you
                    choose to participate in such events.
                    <br/> <br/>
                    Your chats and other forms of communication are processed in order to operate the service.
                    <br/>
                    If you choose to contact us, we will process your communication for the purpose of completing your
                    request.
                    <br/> <br/>
                    Information about you as you interact with other users, such as when somebody submits a report
                    involving you.
                    <br/>
                    Information collected in an automated fashion includes usage information, device information,
                    statistics, your activity, features you use, and interactions with you. <br/>This encompasses
                    details
                    about your device, hardware identifiers that may uniquely identify you as a user, IP address, app
                    settings, and device settings.
                    <br/> <br/>
                    Precise geolocation data if you choose to share such information with us in order to provide
                    tailored results.
                </div>
            </div>
            <div id="section3">
                <div className="mission-text-terms">Why we need your information and how we use it</div>
                <div className="terms-text">
                    Your information is collected to enable the service to function and facilitate interaction with
                    other people while maintaining community safety. <br/> <br/>
                    Based on the information shared with us, we can display your profile to others, communicate with
                    you, provide necessary support, manage your account,
                    recommend you to other users, enable users to search for you, and improve our
                    service. <br/> <br/> Additionally,
                    we may need your information to comply with the law,
                    prevent and detect illegal or fraudulent actions, or any other forms of abuse towards other members
                    of our society or the service itself.
                    Some information may also be required to assist with law enforcement actions concerning you.
                </div>
            </div>
            <div id="section4">
                <div className="mission-text-terms">How and why we share you information</div>
                <div className="terms-text">

                    Your information is shared only when there is a legitimate interest to do so, such as fulfilling
                    legal obligations, protecting our rights and the rights of other users, when required by law, with
                    your consent, or when you request it.
                    <br/> <br/>
                    We may also share your information with our vendors or service providers to operate and provide the
                    service.
                    <br/> <br/>
                    Information that cannot identify you as an individual, in a non-human readable form, may be
                    disclosed without your consent, primarily for the purposes of statistics or advertisement.
                    <br/>
                    Your information is not shared across the borders of the <b>United Kingdom</b> unless explicitly
                    needed to fulfill the
                    contract and provide the service.<br/> <br/>For example servers hosting the service may be located
                    in <b>Ireland</b> or in the territory of <b>European Union.</b>
                </div>
            </div>

            <div id="section5">
                <div className="mission-text-terms">Your rights</div>
                <div className="terms-text">
                    Your data is under your control, and you have the full right to access, amend, remove, or update it
                    as you wish.<br/><br/>
                    You can close your account, revoke permissions, or uninstall the app. However, uninstalling the app
                    from your mobile device will not automatically delete your account with us; it will only stop us
                    from collecting information about how you use our product. If you wish to close your account
                    entirely, this can be done via the app or by explicit request, and it will remove your profile from
                    search results.<br/><br/>
                    You can reinstate your profile within <b>30 calendar days</b> of such a request; after that date,
                    your profile cannot be reinstated.<br/><br/>
                    You have the right to access a copy of your information upon request via post or email specified in
                    the contact section. We will aim to provide you with such a copy within <b>30 working days</b> from
                    the date of your request.
                </div>
            </div>

            <div id="section6">
                <div className="mission-text-terms">Restrictions</div>
                <div className="terms-text">
                    Access to the services is restricted to users living in the territory of the <b>United
                    Kingdom.</b> You must be at
                    least <b>18 years old</b> at the date of registration;
                    we do not allow children to take part in our app. We reserve the right to suspend your account
                    if we
                    suspect that you're <b>under the age of 18.</b>
                </div>
            </div>

            <div id="section7">
                <div className="mission-text-terms">How long your information are kept</div>
                <div className="terms-text">
                    Your information will be retained only for as long as necessary for legitimate business purposes and
                    as required by applicable law.
                    When you request the closure of your account, it will no longer be visible to other users of the app
                    once the request is successfully processed.
                    <br/><br/>
                    We commit to retaining your data only to comply with legal requirements, for tax purposes, or to
                    investigate any unlawful or harmful conduct.
                    Additionally, we may retain your data for as long as necessary to protect the vital interests of
                    other users in cases involving you.
                    <br/><br/>
                    Furthermore, accounts inactive for a period longer than one year may be subject to closure without
                    any notice prior to that.
                </div>
            </div>
            <div id="section8">
                <div className="mission-text-terms">Further Changes</div>
                <div className="terms-text">
                    We reserve the right to make changes to this policy at any time. We will provide you with <b>30
                    calendar days</b> notice before these changes
                    take effect, during which you may withdraw
                    from your contract with us by requesting to close your account. If you fail to do so or continue to
                    use our product after this date, you fully accept these changes.
                </div>
            </div>

            <div id="section9">
                <div className="mission-text-terms">Waiver</div>
                <div className="terms-text">
                    By signing up with us you hereby waive, release, and discharge <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b
                    className="pink">SwiftCrush</b></a> and its affiliates, officers, directors, employees, agents, and
                    representatives from any and all liability, claims, demands, actions, or causes of action arising
                    out of or related to any loss, damage, injury, or harm that may occur to you or your property as a
                    result of using the app or interacting with other users.
                    <br/><br/>
                    You understand that <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b
                    className="pink">SwiftCrush</b></a> collects and processes personal information
                    as outlined in this document, and consent to such collection and processing of your data.
                    <br/><br/>
                    You agree to indemnify and hold harmless <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b
                    className="pink">SwiftCrush</b></a> and its affiliates,
                    officers, directors,
                    employees, agents, and representatives from any and all claims, liabilities, damages, or expenses
                    arising out of or related to your use of the app.
                    <br/>
                    You acknowledge that this waiver of liability is binding upon yourself, your heirs, executors,
                    administrators, and assigns.

                </div>
            </div>
            <div id="section10">
                <div className="mission-text-terms">Contact</div>
                <div className="terms-text privacyTermsText">
                    Should you have any questions in relation to your data or general inquiries, you can contact us via
                    post or email.
                    <b>
                        <br/><br/>
                        3rd Floor<br/>
                        86-90 Paul Street<br/>
                        EC2A 4NE<br/>
                        London, United Kingdom<br/>
                    </b>
                    <br/>
                    <b className="pink">Complaints / Disputes</b> : <a
                    href="mailto:complaints@swiftcrush.com">complaints@swiftcrush.com</a><br/>
                    <b className="pink">Your Information / GDPR Requests</b> : <a
                    href="mailto:gdpr@swiftcrush.com">gdpr@swiftcrush.com</a><br/>
                    <b className="pink">Other Inquiries</b> : <a
                    href="mailto:inquiries@swiftcrush.com">inquiries@swiftcrush.com</a><br/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Privacy;